/*.about{
  background:url(../../images/PagesBg1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}*/

h3{
     font-size: 3rem;
     color:var(--blue);
     padding: 3rem 2rem;
     text-align: center;
     text-transform: uppercase;
  }

 .containerAuthor{
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: beige;
  border-radius: 10%;
 }
.containerAuthor img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.whyChoose{
  width: 200px;
  height: 550px
}

