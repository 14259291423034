/*.services{
    background:url(../../images/PagesBg1.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}*/
.services h2{
    font-size: 3rem;
    color:var(--blue);
    padding: 3rem 2rem;
    text-align: center;
 }
.services h3{
    font-size: 2rem;
    color:var(--blue);
    text-align: center;
}
