/*.head{
    padding:1rem;
    border-bottom: var(--border);
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(40px);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.head .logo{
    width: 80px;
    height: 80px;
}
.head .head-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.head .head-links a{
    margin: 0 1rem;
    font-size: 1.7rem;
    color: var(--black);
}

.head .head-links a:hover{
    color: var(--blue);
}

.head .head-phone{
    display: none;
}
.head-links-mobile{
    display: none;
}
@media screen and (max-width: 770px){
    
    .head .logo{
        width: 50px;
        height: 50px;
    }
    .head .head-links {
        display: none;
    }
    .head .head-phone{
        display: block;
    }
   .head-links-mobile{
        position: fixed;
        backdrop-filter: blur(40px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 80px;
        right: 0px;
        width: 50%;
        z-index: 100;
        
    }
    .head-links-mobile a{
        margin-bottom: 1rem;
        color: var(--black);
        font-size: 2rem;
    }
}*/
.logo{
    width: 80px;
    height: 80px;
}
.navigation {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    z-index: 100;
  }
  
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  
  
  
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 1.7rem;
    color: var(--black);
  }
  .navigation-menu li a:hover{  color: var(--blue);}

  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #2642af;
  }
  
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background-color: white;
      border-top: 1px solid black;
      display: none;
    }
  
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
  
    .navigation-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
  
    .navigation-menu li:hover {
      background-color: #eee;
    }
  
    .navigation-menu.expanded ul {
      display: block;
    }
  }
  