/*.about{
  background:url(../../images/PagesBg1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}*/
.about .row{
   min-height: 70vh;
}
.about h3{
    font-size: 3rem;
    color:var(--blue);
    padding: 3rem 2rem;
    text-align: center;
    text-transform: uppercase;
 }
.about img{
    width: 540px;
    float: right;
}
.about .content span{
   font-size: 2rem;
   color:var(--blue);
}

.about .content h3{
   font-size: 3rem;
   color:var(--black);
   margin-top: 1rem;
}

.about .content p{
   padding:0.5rem 1rem;
   font-size: 1.4rem;
   color:var(--light-color);
   line-height: 2;
}

.form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #15172b;
    border-radius: 20px;
    box-sizing: border-box;
    
    padding: 20px;

}
  
.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

  .ic2 {
    margin: 30px 30px;
  }
  
  .input {
    background-color: #303245;
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: #eee;
    font-size: 18px;
    height: 100%;
    outline: 0;
    padding: 4px 20px 0;
    width: 100%;
  }
  
  .cut {
    background-color: #15172b;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 76px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }
  
  .placeholder {
    color: #65657b;
    font-family: sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .input:not(:placeholder-shown) ~ .placeholder {
    color: #808097;
  }
  
  .input:focus ~ .placeholder {
    color: #dc2f55;
  }
  
  .submit {
    background-color: var(--blue);
    border-radius: 12px;
    border: 0;
    box-sizing: border-box;
    color: var(--white);
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    outline: 0;
    text-align: center;
    width: 100%;
  }
  
  .submit:active {
    background-color: #06b;
  }
  @media screen and (max-width: 768px) {
    .form{
      flex-direction: column;
    }
  }
  @media screen and (max-width: 400px) {
    .about img{
      width: 100%;
      float: right;
    }
    .form{
      flex-direction: column;
    }
   
  }