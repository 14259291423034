@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

:root{
   --blue:#00b8b8;
   --black:#333;
   --white:#fff;
   --light-color:#666;
   --light-bg:#eee;
   --border:.2rem solid rgba(0,0,0,.1);
   --box-shadow:0 .5rem 1rem rgba(0,0,0,.1);
}

*{
   font-family: 'Poppins', sans-serif;
   margin:0; padding:0;
   box-sizing: border-box;
   outline: none; border:none;
   text-decoration: none !important;
}

*::-webkit-scrollbar{
   height: .5rem;
   width: 1rem;
}

*::-webkit-scrollbar-track{
   background-color: transparent;
}

*::-webkit-scrollbar-thumb{
   background-color: var(--blue);
}

html{
   font-size: 62.5%;
   overflow-x: hidden;
   scroll-behavior: smooth;
   scroll-padding-top: 6.5rem;
}

/*section{
  padding:7rem 2rem;
}*/

.heading{
  text-align: center;
  font-size: 4rem;
  color:var(--black);
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 3rem;
}

.link-btn{
  display: inline-block;
  padding:1rem 3rem;
  border-radius: .5rem;
  background-color: var(--blue);
  cursor: pointer;
  font-size: 1.7rem;
  color:var(--white);
  text-align: center;
}

.link-btn:hover{
  background-color: var(--black);
  color:var(--white);
}
/* media queries  */

@media (max-width:991px){

  html{
     font-size: 55%;
  }

  .header .link-btn{
     display: none;
  }

  section{
     padding:5rem 2rem;
  }

}

@media (max-width:768px){

  section{
     padding:3rem 1rem;
  }

  #menu-btn{
     display: inline-block;
     transition: .2s linear;
  }

  #menu-btn.fa-times{
     transform: rotate(180deg);
  }

  .header .nav{
     position: absolute;
     top:99%; left:0; right:0;
     background-color: var(--white);
     border-top: var(--border);
     border-bottom: var(--border);
     padding:1rem 0;
     text-align: center;
     flex-flow: column;
     clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
     transition: .2s linear;
  }

  .header .nav.active{
     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .header .nav a{
     margin:1rem 0;
     font-size: 2rem;
  }

  .home{
     background-position: left;
  }

  .home .content{
     width: auto;
  }

}

@media (max-width:450px){

  html{
     font-size: 50%;
  }

  .home .content h3{
     font-size: 4rem;
  }

  .heading{
     font-size: 3rem;
  }

}