p{
    padding:0.5rem 1rem;
    font-size: 1.4rem;
    color:var(--light-color);
    line-height: 2;
 }
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 320px;
    background: #fff;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    filter: blur(4px);
    background: #d1ebff;
  }
  .swiper-slide-active{
    filter: blur(0px);
    background: #fff;
  }
.swiper-slide img {
    display: block;
    width: 20%;
  }
.testimonialBox{
    position: relative;
    width: 100%;
    padding: 40px;
    padding-top: 90px;
    color: #999;
}
.testimonialBox .quote{
    position: absolute;
    top: 20px;
    right: 30px;
    opacity: 0.2;
}
.testimonialBox .details{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.testimonialBox .details .imgBx{
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

}
.testimonialBox .details .imgBx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.testimonialBox .details h3{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #2196f3;
    line-height: 1.1em;
}
.testimonialBox .details h3 span{
    font-size: 12px;
    color: #666;
    
}
